import twitter_ico from "../assets/img/Twitter_icon-icons.com_66803.png";
const Footer = () => {
  return (
    <div className="footer">
      <span>
        ©copyright -{" "}
        <a href="https://twitter.com/GardenGameNFT" target="_blank">
          GardenGame 2022 <img src={twitter_ico} alt="Icone twitter" />
        </a>
      </span>
    </div>
  );
};

export default Footer;
