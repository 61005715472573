import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddressWallet } from "../redux/WalletSlice";

const LogInWallet = () => {
  // const [walletAddress, setWalletAddress] = useState(null);
  const walletAddress = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  const checkIfWalletIsConnected = async () => {
    try {
      const { solana } = window;

      if (solana) {
        if (solana.isPhantom) {
          console.log("Phantom wallet found!");

          const response = await solana.connect({ onlyIfTrusted: true });
          // setWalletAddress(response.publicKey.toString());
          dispatch(setAddressWallet(response.publicKey.toString()));
          console.log(
            "1Connected with Public Key:",
            response.publicKey.toString()
          );
        } //else {
        //   setWalletAddress(null);
        // }
      } else {
        // setWalletAddress(null);
        alert("Solana object not found! Get a Phantom Wallet 👻");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const connectWallet = async () => {
    const { solana } = window;

    if (solana) {
      const response = await solana.connect();
      console.log("Connected with Public Key:", response.publicKey.toString());
      // setWalletAddress(response.publicKey.toString());
      dispatch(setAddressWallet(response.publicKey.toString()));
    }
  };

  const renderNotConnectedContainer = () => (
    <button
      className="cta-button connect-wallet-button"
      onClick={connectWallet}
    >
      Connect to Wallet
    </button>
  );

  useEffect(() => {
    const onLoad = async () => {
      await checkIfWalletIsConnected();
    };
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);
  }, []);

  return <Fragment>{!walletAddress && renderNotConnectedContainer()}</Fragment>;
};

export default LogInWallet;
