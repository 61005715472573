import { createSlice } from "@reduxjs/toolkit";

const walletSlice = createSlice({
  name: "wallet",
  initialState: null,
  reducers: {
    setAddressWallet: (state, action) => {
      return action.payload;
    },
  },
});

export default walletSlice;
export const { setAddressWallet } = walletSlice.actions;
