const rate_sol_muta = 0.005217845;

export const changeMutaToSol = (muta) => {
  return rate_sol_muta * muta;
};
export const changeSolToMuta = (sol) => {
  return sol / rate_sol_muta;
};

// export const getDayDiff = (startDate, endDate) => {
//   const msInDay = 24 * 60 * 60 * 1000;

//   return Math.round((endDate.getTime() - startDate.getTime()) / msInDay);
// };

export const getDayDiffFromToday = (endDate) => {
  const msInDay = 24 * 60 * 60 * 1000;

  return Math.round((endDate.getTime() - new Date().getTime()) / msInDay);
};
