import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreateRaffle from "./pages/CreateRaffle";
import Home from "./pages/Home";
import MyRaffles from "./pages/MyRaffles";
import Raffle from "./pages/Raffle";
import RaffleFinished from "./pages/RaffleFinished";
import { store } from "./redux/Store";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/create_raffle" element={<CreateRaffle />} />
          <Route path="/my_raffles" element={<MyRaffles />} />
          <Route path="/raffle_finished" element={<RaffleFinished />} />
          <Route path="/raffle/:slug" element={<Raffle />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
