import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDayDiffFromToday } from "../utils/Utils";

const RaffleTile = (props) => {
  let navigate = useNavigate();
  const raffles = useSelector((state) => state.raffle);
  const raffle = raffles.find((raffle) => raffle.id === props.id);

  const walletAddress = useSelector((state) => state.wallet);
  const purchased_ticket = raffle.candidates.indexOf(walletAddress) !== -1;
  const lasting_days = getDayDiffFromToday(new Date(raffle.end_date));
  return (
    <div
      className={
        "raffle_tile " +
        (raffle.status === "Over" ? "raffle_over " : "") +
        (purchased_ticket ? "raffle_participed " : "")
      }
      onClick={() => navigate("/raffle/" + raffle.id)}
    >
      <div className="infos-raffle-div">
        <ul>
          <li>owner: {raffle.owner_username}</li>
          <li>lot number: {raffle.lots.length}</li>
          <li>{raffle.candidates.length} tickets sold</li>
          {lasting_days >= 0 && <li>{lasting_days} days lasting</li>}
          {lasting_days < 0 && <li>raffle finished</li>}
        </ul>
      </div>

      <div className="nft-img-div">
        {raffle.lots.map((url, index) => (
          <img className="nft-img" src={url} alt="image" />
          // <li key={index}>
          //   <input
          //     name="status_radio"
          //     id={status}
          //     type="radio"
          //     checked={status === radioValue}
          //     onChange={(e) => setRadioValue(e.target.id)}
          //   />
          //   <label htmlFor={status}>{status}</label>
          // </li>
        ))}
      </div>
    </div>
  );
};

export default RaffleTile;
