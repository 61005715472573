import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LogInWallet from "../components/LogInWallet";
import Navigation from "../components/Navigation";
import { buyTicket } from "../redux/RaffleSlice";
import { changeMutaToSol, getDayDiffFromToday } from "../utils/Utils";
import twitter_ico from "../assets/img/Twitter_icon-icons.com_66803.png";

const Raffle = () => {
  const dispatch = useDispatch();
  let { slug } = useParams();
  const raffles = useSelector((state) => state.raffle);
  const raffle = raffles.find((raffle) => raffle.id === slug);

  // console.log("date", new Date("11/02/21 22:22:02"));
  // const date2 = new Date().toString();
  // console.log("date2", date2);
  // console.log("date3", new Date(date2));
  const walletAddress = useSelector((state) => state.wallet);
  const purchased_ticket = raffle.candidates.indexOf(walletAddress) !== -1;
  const is_ongoing = getDayDiffFromToday(new Date(raffle.end_date)) >= 0;
  // const buyTicket = () => dispatch(incTicketsSold(slug));
  const handleBuyTicket = () =>
    dispatch(
      buyTicket({
        raffle_id: slug,
        walletCandidate: walletAddress,
      })
    );
  // console.log("buy")

  const renderConnectedContainer = () => (
    <button className="cta-button flat-button" onClick={handleBuyTicket}>
      Buy
    </button>
  );
  return (
    <Fragment>
      <Header text="Raffle page" />
      <Navigation />
      <div className="raffle">
        <ul>
          <li>owner: {raffle.owner_username}</li>
          {raffle.owner_twitter && (
            <a href={raffle.owner_twitter} target="_blank">
              <img src={twitter_ico} alt="Icone twitter" />
            </a>
          )}
          <li>lot number: {raffle.lots.length}</li>
          <li>{raffle.candidates.length} tickets sold</li>
          {is_ongoing && (
            <li>
              {getDayDiffFromToday(new Date(raffle.end_date))} days lasting
            </li>
          )}
          {!is_ongoing && <li>raffle finished</li>}
          <li>
            Price: {raffle.muta_price} Muta /{" "}
            {changeMutaToSol(raffle.muta_price)} Sol
          </li>
          {/* {!walletAddress && <LogInWallet />} */}
          {walletAddress &&
            is_ongoing &&
            !purchased_ticket &&
            renderConnectedContainer()}
          {walletAddress && purchased_ticket && <p>You're participating!</p>}
        </ul>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Raffle;
