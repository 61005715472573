import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter } from "../redux/FilteredRaffleSlice";

const Filter = () => {
  const dispatch = useDispatch();
  const raffles = useSelector((state) => state.raffle);

  const [lotNumber, setLotNumber] = useState(1);
  // const [radioValue, setRadioValue] = useState("Ongoing");
  const [searchValue, setSearchValue] = useState("");
  // const status = ["Ongoing", "Over"];

  useEffect(() => {
    dispatch(
      filter({
        // raffle_status: radioValue,
        raffle_lotNumber: lotNumber,
        raffle_search: searchValue,
        raffles: raffles,
      })
    );
  }, [lotNumber, searchValue, raffles]);

  return (
    <div className="filter_container">
      <ul>
        <li>
          {/* <label htmlFor="search">Search</label> */}
          <input
            id="search"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </li>
        <li>
          <label htmlFor="lot_number_range">{lotNumber} batches minimum </label>
          <input
            id="lot_number_range"
            type="range"
            min="1"
            max="9"
            defaultValue={lotNumber}
            onChange={(e) => setLotNumber(e.target.value)}
          />
        </li>
        {/* {status.map((status, index) => (
          <li key={index}>
            <input
              name="status_radio"
              id={status}
              type="radio"
              checked={status === radioValue}
              onChange={(e) => setRadioValue(e.target.id)}
            />
            <label htmlFor={status}>{status}</label>
          </li>
        ))} */}
      </ul>
    </div>
  );
};

export default Filter;
