import { configureStore } from "@reduxjs/toolkit";
import filteredRaffleSlice from "./FilteredRaffleSlice";
import raffleSlice from "./RaffleSlice";
import walletSlice from "./WalletSlice";

export const store = configureStore({
  reducer: {
    raffle: raffleSlice.reducer,
    filteredRaffle: filteredRaffleSlice.reducer,
    wallet: walletSlice.reducer,
  },
});
