import { useSelector } from "react-redux";
import LogInWallet from "./LogInWallet";
import ant_logo from "../assets/img/ant_logo.png";

const Header = ({ text }) => {
  const walletAddress = useSelector((state) => state.wallet);

  return (
    <div className="header">
      <div className="img-header-div">
        <img src={ant_logo} alt="ant logo" />
      </div>

      <div className="header-div-title">
        <h1>{text}</h1>
        {walletAddress && <h5>{walletAddress}</h5>}
        {!walletAddress && <LogInWallet />}
      </div>

      <div className="img-header-div">
        <img src={ant_logo} alt="ant logo" />
      </div>
    </div>
  );
};

export default Header;
