import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Navigation from "../components/Navigation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { addRaffle } from "../redux/RaffleSlice";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { changeMutaToSol, changeSolToMuta } from "../utils/Utils";
import { useSelector } from "react-redux";

const CreateRaffle = () => {
  const [randomIdValue, setRandomIdValue] = useState(
    Math.floor(Math.random() * 10 ** 11).toString()
  );
  const [mutaPrice, setMutaPrice] = useState(0);
  const walletAddress = useSelector((state) => state.wallet);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState, control } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });
  const { isSubmitting, isSubmitted, isSubmitSuccessful } = formState;

  const onSubmit = async (data) => {
    await wait(200);
    let newData = { ...data };
    newData.draw_date = data.draw_date.toString();
    dispatch(
      addRaffle({
        raffle_id: randomIdValue,
        owner_address: walletAddress,
        ...newData,
      })
    );
    console.log("add", { raffle_id: randomIdValue, ...newData });
    navigate("/raffle/" + randomIdValue);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="">
      <Header text="Anthill" />
      <Navigation />

      <div className="create_raffle">
        <div className="row">
          <div className="left_field">
            <label className="" htmlFor="raffle_nft">
              Choose NFT
            </label>
          </div>
          <div className="right_field" id="raffle_nft"></div>
        </div>

        <div className="row">
          <div className="price">
            <label className="" htmlFor="muta_price">
              Muta price
            </label>
          </div>
          <div className="price">
            <input
              {...register("muta_price")}
              id="muta_price"
              type="float"
              className="form-control"
              onChange={(e) => setMutaPrice(e.target.value)}
            />
          </div>

          <p>
            Price: {mutaPrice} Muta / {changeMutaToSol(mutaPrice)} Sol
          </p>
        </div>
        <div className="row">
          <div className="price">
            <label className="" htmlFor="sol_price">
              Sol price
            </label>
          </div>
          <div className="price">
            <input
              {...register("sol_price")}
              id="sol_price"
              type="float"
              className="form-control"
              onChange={(e) => {
                setMutaPrice(changeSolToMuta(e.target.value));
                console.log("hy");
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="left_field">
            <label className="" htmlFor="end_date">
              Raffle end
            </label>
          </div>
          <div className="right_field" id="end_date">
            <Controller
              defaultValue={new Date()}
              control={control}
              name="draw_date"
              render={({ field: { onChange, onBlur, value } }) => (
                <DatePicker
                  selected={value}
                  onBlur={onBlur}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="left_field">
            <label className="" htmlFor="raffle_owner_user">
              Username
            </label>
          </div>
          <div className="right_field">
            <input
              {...register("raffle_owner_user")}
              id="raffle_owner_user"
              type="text"
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="left_field">
            <label className="" htmlFor="raffle_owner_twitter">
              Twitter url
            </label>
          </div>
          <div className="right_field">
            <input
              {...register("raffle_owner_twitter")}
              id="raffle_owner_twitter"
              type="text"
              className="form-control"
              placeholder="https://twitter.com/GardenGameNFT"
            />
          </div>
        </div>

        <button className="cta-button flat-button" disabled={isSubmitting}>
          Create
        </button>

        {isSubmitted && <p className="success">Raffle created!</p>}
      </div>
      <Footer />
    </form>
  );

  // return (
  //   <form onSubmit={handleSubmit(onSubmit)} className="">
  //     <Header text="Anthill" />
  //     <Navigation />
  //     <div className="create_raffle">
  //       <div className="row">
  //         <div className="col-md-6">
  //           <label className="col-40" htmlFor="raffle_id">
  //             Raffle identifier
  //           </label>
  //           <input
  //             // {...register("raffle_id")}
  //             id="raffle_id"
  //             type="text"
  //             className="form-control"
  //             defaultValue={randomIdValue}
  //             disabled={true}
  //           />
  //         </div>
  //       </div>

  //       <div className="row">
  //         <div className="col-md-6">
  //           <label className="col-40" htmlFor="raffle_title">
  //             Title
  //           </label>
  //           <input
  //             {...register("raffle_title")}
  //             id="raffle_title"
  //             type="text"
  //             className="form-control"
  //           />
  //         </div>
  //       </div>

  //       <div className="row">
  //         <div className="col-md-6">
  //           <label className="col-40" htmlFor="raffle_description">
  //             Description
  //           </label>
  //           <input
  //             {...register("raffle_description")}
  //             id="raffle_description"
  //             type="textarea"
  //             className="form-control"
  //           />
  //         </div>
  //       </div>

  //       <div className="row">
  //         <div className="col-md-6">
  //           <label className="col-40" htmlFor="draw_date">
  //             Draw date
  //           </label>
  //           <Controller
  //             defaultValue={new Date()}
  //             control={control}
  //             name="draw_date"
  //             render={({ field: { onChange, onBlur, value } }) => (
  //               <DatePicker
  //                 selected={value}
  //                 onBlur={onBlur}
  //                 onChange={onChange}
  //               />
  //             )}
  //           />
  //         </div>
  //       </div>

  //       <button
  //         className="cta-button connect-wallet-button"
  //         disabled={isSubmitting}
  //       >
  //         Create
  //       </button>

  //       {isSubmitted && <p className="success">Raffle created!</p>}
  //     </div>
  //     <Footer />
  //   </form>
  // );
};

export default CreateRaffle;

const wait = (duration = 1000) => {
  return new Promise((resolve) => window.setTimeout(resolve, duration));
};

const schema = yup
  .object({
    // raffle_title: yup.string().required(),
    // raffle_description: yup.string().required(),
    draw_date: yup.date().min(new Date()).required(),
  })
  .required();
