import { Fragment } from "react";
import { useSelector } from "react-redux";
import Filter from "../components/Filter";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navigation from "../components/Navigation";
import Panel from "../components/Panel";

const MyRaffles = () => {
  const walletAddress = useSelector((state) => state.wallet);

  return (
    <Fragment>
      <Header text="Anthill" />
      <Navigation />
      <Filter />
      <Panel candidate={walletAddress}/>
      <Footer />
    </Fragment>
  );
};

export default MyRaffles;
