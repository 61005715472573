import { createSlice } from "@reduxjs/toolkit";

const raffleSlice = createSlice({
  name: "raffle",
  initialState: [
    {
      id: "55060592186",
      status: "Ongoing",
      lots: [
        "https://bafybeicsm34dubjpgm4jfwlmvvrd7cf2vdc4ievljdvo6ms6jrxec3kvvq.ipfs.nftstorage.link/659.png?ext=png",
        "https://bafybeif3e4jmyccucjzoqar4ylxg7fwbruiwdf6bupmn736kd2ikhkfylm.ipfs.nftstorage.link/2276.png?ext=png",
      ],
      muta_price: 150.3,
      owner_address: "2hDHBfXMnpjw8HZWL2qpzg3qc45T6VXRVns4x4Hb1C6H",
      owner_username: "King",
      end_date: "07/20/2022",
      candidates: [
        "zdczff",
        "efz",
        "efzef",
        "aa",
        "ll",
        "oo",
        "aa",
        "ll",
        "oo",
      ],
    },
    {
      id: "50675118054",
      status: "Ongoing",
      lots: [
        "https://bafybeifnpe2qy565alb3bs7czeebzf6hwizlveapyzsffxspwd2ducwg4e.ipfs.nftstorage.link/1223.png?ext=png",
        "https://bafybeicppfluwcsisidu4rxyqgpusgb25s5evczmjyfs572v7uadownqfi.ipfs.nftstorage.link/2778.png?ext=png",
        "https://bafybeigeppvsnl4kyvyv34a3grmrustw6chpxnzztepxgcbql5kmvwsds4.ipfs.nftstorage.link/4646.png?ext=png",
        "https://bafybeicwwpemkjxl6k7axemdmrefkdx5w5jnuz6qwchumynnj2inzhodzy.ipfs.nftstorage.link/3707.png?ext=png",
      ],
      muta_price: 250,
      owner_address: "2hDHBfXMnpjw8HZWL2qpzg3qc45T6VXRVns4x4Hb1C6H",
      owner_username: "King",
      end_date: "07/13/2022",
      candidates: ["qs", "dd", "ff", "aa", "ll", "oo"],
    },
    {
      id: "21089359254",
      status: "Over",
      lots: [
        "https://bafybeigeppvsnl4kyvyv34a3grmrustw6chpxnzztepxgcbql5kmvwsds4.ipfs.nftstorage.link/4646.png?ext=png",
        "https://bafybeicwwpemkjxl6k7axemdmrefkdx5w5jnuz6qwchumynnj2inzhodzy.ipfs.nftstorage.link/3707.png?ext=png",
        "https://bafybeiecq75bebhvgaks6zi6cdwykpvhzibz6szjzybwjgjy67prlur3dq.ipfs.nftstorage.link/1851.png?ext=png",
      ],
      muta_price: 103,
      owner_address: "qqqq",
      owner_username: "",
      end_date: "01/06/2022",
      candidates: ["aa", "gggg", "aa", "ll", "oo"],
    },
    {
      id: "28050962889",
      status: "Over",
      lots: [
        "https://bafybeicsm34dubjpgm4jfwlmvvrd7cf2vdc4ievljdvo6ms6jrxec3kvvq.ipfs.nftstorage.link/659.png?ext=png",
        "https://bafybeif3e4jmyccucjzoqar4ylxg7fwbruiwdf6bupmn736kd2ikhkfylm.ipfs.nftstorage.link/2276.png?ext=png",
      ],
      muta_price: 66,
      owner_address: "kkk",
      owner_username: "Azo",
      end_date: "06/21/2022",
      candidates: [
        "2hDHBfXMnpjw8HZWL2qpzg3qc45T6VXRVns4x4Hb1C6H",
        "ggyh",
        "yyhh",
        "aa",
        "ll",
        "oo",
      ],
    },
    {
      id: "17913526817",
      status: "Ongoing",
      lots: [
        "https://bafybeig264bzg5ksctd56qln2xqbitkw5ssdcfauoqhduq2scdwdmapwqu.ipfs.nftstorage.link/1562.png?ext=png",
      ],
      muta_price: 500.35,
      owner_address: "oooo",
      owner_username: "Opy",
      end_date: "07/25/2022",
      candidates: ["iik", "jj", "tyh"],
    },
  ],
  reducers: {
    addRaffle: (state, action) => {
      const newRaffle = {
        id: action.payload.raffle_id,
        muta_price: action.payload.muta_price,
        // title: action.payload.raffle_title,
        // description: action.payload.raffle_description,
        status: "Ongoing",
        lots: [
          "https://bafybeig264bzg5ksctd56qln2xqbitkw5ssdcfauoqhduq2scdwdmapwqu.ipfs.nftstorage.link/1562.png?ext=png",
        ],
        owner_address: action.payload.owner_address,
        owner_username: action.payload.raffle_owner_user,
        owner_twitter: action.payload.raffle_owner_twitter,
        end_date: action.payload.draw_date,
        candidates: [],
      };
      state.push(newRaffle);
    },
    // incTicketsSold: (state, action) => {
    //   console.log("buy");
    //   // state.find((raffle) => raffle.id === action.payload).ticketsSold++;
    // },
    buyTicket: (state, action) => {
      let list_candidates = state.find(
        (raffle) => raffle.id === action.payload.raffle_id
      ).candidates;
      if (list_candidates.indexOf(action.payload.walletCandidate) === -1) {
        list_candidates.push(action.payload.walletCandidate);
      }
    },
    deleteRaffle: (state, action) => {},
    drawRaffle: (state, action) => {},
  },
});

export default raffleSlice;
export const { addRaffle, buyTicket } = raffleSlice.actions;
