import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  const walletAddress = useSelector((state) => state.wallet);
  return (
    <div className="navigation">
      <ul>
        <NavLink
          to="/home"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
        >
          <li>Ongoing</li>
        </NavLink>

        {walletAddress && (
          <Fragment>
            <NavLink
              to="/my_raffles"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              <li>My raffles</li>
            </NavLink>

            <NavLink
              to="/create_raffle"
              className={(nav) => (nav.isActive ? "nav-active" : "")}
            >
              <li>Create raffle</li>
            </NavLink>
          </Fragment>
        )}

        <NavLink
          to="/raffle_finished"
          className={(nav) => (nav.isActive ? "nav-active" : "")}
        >
          <li>Finished</li>
        </NavLink>
      </ul>
    </div>
  );
};

export default Navigation;
