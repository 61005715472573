import { Fragment } from "react";
import Filter from "../components/Filter";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navigation from "../components/Navigation";
import Panel from "../components/Panel";

const RaffleFinished = () => {
  return (
    <Fragment>
      <Header text="Anthill" />
      <Navigation />
      <Filter />
      <Panel raffle_status="Over" />
      <Footer />
    </Fragment>
  );
};

export default RaffleFinished;
