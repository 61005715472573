import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { getDayDiffFromToday } from "../utils/Utils";
import RaffleTile from "./RaffleTile";

const Panel = ({ raffle_status, candidate }) => {
  const filteredRaffles = useSelector((state) => state.filteredRaffle);
  // console.log("candidate", candidate)
  return (
    <div className="panel">
      {raffle_status &&
        filteredRaffles
          .filter(({ status }) => status.includes(raffle_status))
          .sort(
            (a, b) =>
              getDayDiffFromToday(new Date(a.end_date)) -
              getDayDiffFromToday(new Date(b.end_date))
          )
          .map((raffle) => <RaffleTile key={raffle.id} id={raffle.id} />)}
      {candidate &&
        filteredRaffles
          .filter(({ candidates }) => candidates.indexOf(candidate) !== -1)
          .map((raffle) => <RaffleTile key={raffle.id} id={raffle.id} />)}
    </div>
  );
};

export default Panel;
