import { createSlice } from "@reduxjs/toolkit";

const filteredRaffleSlice = createSlice({
  name: "filteredRaffle",
  initialState: [],
  reducers: {
    filter: (state, action) => {
      // const raffles = useSelector((state) => state.raffle);
      const raffles = action.payload.raffles; // TODO Trouver un acces à raffleSlice sans passer par un composant intermediaire

      return (
        raffles
          // .filter(({ status }) => status.includes(action.payload.raffle_status))
          .filter(
            ({ lots }) =>
              parseInt(lots.length) >= parseInt(action.payload.raffle_lotNumber)
          )
          .filter(
            ({ id, owner_username, owner_address }) =>
              id
                .toLowerCase()
                .includes(action.payload.raffle_search.toLowerCase()) ||
              owner_username
                .toLowerCase()
                .includes(action.payload.raffle_search.toLowerCase()) ||
              owner_address.includes(action.payload.raffle_search.toLowerCase())
          )
      );
    },
  },
});

export default filteredRaffleSlice;
export const { filter } = filteredRaffleSlice.actions;
